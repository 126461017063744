export const environment = {
  production: true,
  domain: 'https://app.pipooh.com.br/',
  api: 'https://api.pipooh.com.br/'
};

export const environmentAspNet = {
  production: true,
  domain: 'https://pipooh.com.br/',
  // api: 'http://localhost:24066/'
  api: 'https://apiv2.pipooh.com.br/'
};

export const environment_v3 = {
  production: true,
  domain: 'https://pipooh.com.br/',
  api: 'https://apiv3.pipooh.com.br/'
};
